<template>
    <v-card width="100%" style="border-radius: 12px; overflow: hidden">
        <v-form @submit.prevent="sendEmail" ref="form">
            <v-card-item>
                <v-card-title>
                    <b>{{ formData && formData.name ? formData.name : '' }}</b>
                    <v-btn @click="setFalse()" elevation="0" density="comfortable" icon="mdi-close"
                        class="x-closeIcon"></v-btn>
                </v-card-title>
            </v-card-item>

            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Account
                            <span style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
                        <AutoComplete :items="accountData" item-value="id" item-title="name" density="compact"
                            placeholder="Account" v-model="formData.account_id" :disabled="showReadOnly" multiple />
                    </v-col>                    
                    <v-col cols="12" v-if="accountError" class="mt-4">
                        <v-error >Account is Required.</v-error>
                    </v-col>
                    <v-col cols="12" class="mt-4">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Email
                            <span style="color: green; padding-left: 2px;">(Example:abc@coreforce.com,123@gmail.com)</span></v-label>
                            <TextFieldComponent density="compact" placeholder="Email"                            
                            @click="changeEmail" 
                            @mouseover="changeEmail" 
                            @input="changeEmail" :rules="emailRule" v-model="formData.email"
                            :disabled="showReadOnly" />
                    </v-col>
                    <v-col cols="12" class="mt-4">
                        <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">CC
                            <span style="color: green; padding-left: 2px;">(Example:abc@coreforce.com,123@gmail.com)</span></v-label>
                            <TextFieldComponent density="compact" placeholder="CC" :rules="nameRule" v-model="formData.cc"
                            :disabled="showReadOnly" />
                    </v-col>
                    
                    <v-col cols="12">
                        <span></span>
                    </v-col>

                </v-row>
            </v-card-text>
            <v-card-action class="mr-3">
                <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
                    <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
                    <v-btn class="save" :loading="saveClicked" type="submit">Send Email</v-btn>
                </v-row>
            </v-card-action>
        </v-form>
    </v-card>
</template>


<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import ListModelService from "@/api/service/ListModel/ListModelService";
import ReportService from "@/api/service/Report/ReportService";
import commonService from "@/common.service";
import AccountService from "@/api/service/Account/AccountService";
export default {
    props: ['id'],
    components: {
        AutoComplete,
        TextFieldComponent
    },
    data() {
        return {
            accountData: [],
            emailRule: [(value) => !!value || "Email is Required."],
            saveClicked: false,
            formData: {},
            listModelData: null,
            accountError: false,
            email:null

        }
    },
    watch: {
        id() {
            if (this.id) {
                this.formData = {};
                this.getReportData(this.id);
            }
        }
    },
    created() {
        if (this.id) {
            this.getReportData(this.id)
        }
        this.getAccountData() 
    },
    methods: {
        async changeEmail(){
            if(this.formData && this.formData.email && this.email && this.formData.email.length <= this.email.length){
                this.formData.email = this.email
            }
        },
        async getReportData(id) {
            try {
                let res = await ListModelService.findAll({ id: id, active: 1 })
                this.formData = res && res.length > 0 ? res[0] : {}                       
                let userData = localStorage.getItem('user_details')
                console.log(userData,"user datata")
                userData = userData?JSON.parse(userData):null
                this.email = userData && userData.email?userData.email :null
                this.formData.email = this.email
            }
            catch (e) {
                console.log(e)
            }
        },
        async getAccountData() {
            try {                
                let res = await AccountService.getAccountByUser()
                this.accountData = res && res.length>0?await commonService.getSort(res, "name", "asc"):[]
                this.accountData = this.accountData.filter(a=>a.active == 1)
            }
            catch (e) {
                console.log(e)
            }
        },

        setFalse() {
            this.$emit("closeDialog");
        },
        async sendEmail() {            
            let validation = await this.$refs.form.validate()
            if (!validation.valid || !this.formData || !this.formData.account_id || this.formData.account_id.length == 0) {
                this.accountError = true
            }
            else {
                try {
                    let res = await ReportService.sendReport(this.formData)
                    console.log(res)
                    this.setFalse()
                    this.$store.dispatch("setSnackbar", { text: "Email Sent Successfully" });
                }
                catch (e) {
                    this.$store.dispatch("setSnackbar", {
                    color: "error",
                    text: "Something Went Wrong Email Not Send",
                    });
                    console.log(e);
                }
            }
        }
    }

}
</script>